import { Routes, Route } from "react-router-dom";

// import DeviceProfile from "./DeviceProfile.page";
import RoleList from "./RoleList.page";
import CreateRole from "../Forms/Roles/CreateRole.page";
export default function RolesMain() {
    return (
        <Routes>
            <Route path="/" element={<RoleList />} />
            {/* <Route path="/:serial" element={<DeviceProfile />} /> */}
            <Route path='/add' element={<CreateRole />} />
        </Routes>
    );
}
