import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import axios from '../../../utils/axios';
// @Mui components
import {
    Box, Button, IconButton, Stack, FormControl, FormLabel, Grid, MenuItem,
    TextField, Typography, Divider, Snackbar, Alert
} from "@mui/material";
import { styled } from "@mui/material/styles";
// Material Icons
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import ConfigurationBanner from '../../../assets/settings_tab_mgiw.svg'
// custom components
import SectionHeader from "../../../components/SectionHeader/SectionHeader.component";
import { BoxItem } from '../../../components/Common/Item.component';

// Formik initial values
const initialValues = {
    slaveName: '',
    manufacturer: '',
    slaveTypeId: '',
    configuration: [{
        address: '',
        attribute: '',
        unit: ''
    }]
}

const validationSchema = Yup.object().shape({
    slaveName: Yup.string().ensure().required('Name is required'),
    manufacturer: Yup.string().ensure().required('Manufacturer is required'),
    slaveTypeId: Yup.number().required('Please select slave type').min(1, 'Incorrect slave id').max(5, 'Incorrect slave id'),
    configuration: Yup.array().of(Yup.object().shape({
        address: Yup.string().required('Register address is required'),
        attribute: Yup.string().required('Attribute name is required'),
        unit: Yup.string()
    }))
        .min(1)
        .max(500)
});

const stypes = [
    {
        "id": 1,
        "name": "Meter"
    },
    {
        "id": 2,
        "name": "PLC"
    },
    {
        "id": 3,
        "name": "HMI"
    },
    {
        "id": 4,
        "name": "IO-Card"
    },
    {
        "id": 5,
        "name": "Other Sensors"
    },
]

const AddSlave = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [slaveTypeList, setSlaveTypeList] = useState(stypes);
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            postData(values);
        }
    });

    const postData = async (data) => {
        const response = await axios.post('/slaves', data);
        const result = await response.data;
        setMessage(result);
        setOpen(true);
        if (result.status) {
            setTimeout(() => {
                navigate('/slaves', { replace: true });
            }, 4000);
        }
    }

    const handleClose = () => {
        setOpen(false);
        navigate('/slaves', { replace: true });
    }

    const handleAddRegister = () => {
        const conf = [...formik.values.configuration];
        conf.push({
            address: '',
            attribute: '',
            unit: ''
        });
        formik.setFieldValue('configuration', conf);
    }


    const handleRemoveRegister = (i) => {
        const conf = [...formik.values.configuration];
        conf.splice(i, 1)
        formik.setFieldValue('configuration', conf);
    }

    const handleRegisterChange = (index, value) => {
        const conf = [...formik.values.configuration];
        conf[index] = { ...conf[index], address: value };
        formik.setFieldValue('configuration', conf);
    }
    const handleAttributeChange = (index, value) => {
        const conf = [...formik.values.configuration];
        conf[index] = { ...conf[index], attribute: value };
        formik.setFieldValue('configuration', conf);
    }
    const handleUnitChange = (index, value) => {
        const conf = [...formik.values.configuration];
        conf[index] = { ...conf[index], unit: value };
        formik.setFieldValue('configuration', conf);
    }


    return (
        <Box>
            <SectionHeader heading="Add Slaves" />
            <Box sx={{ mt: 1 }}>
                <form onSubmit={formik.handleSubmit}>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        justifyContent="space-evenly"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <BoxItem>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={1}
                            >
                                <BoxItem>
                                    <Typography variant='h6'>Slave Device Configuration</Typography>
                                </BoxItem>
                                <BoxItem>
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        {
                                            formik.values.configuration.map((conf, index) => (
                                                <BoxItem key={index}>
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                        alignItems="stretch"
                                                        spacing={2}

                                                    >
                                                        <Grid item xs={11} md={3}>
                                                            <FormControl fullWidth
                                                                error={formik.touched.configuration && Boolean(formik.errors.configuration)}
                                                            >
                                                                <FormLabel component="legend">Register {index + 1}</FormLabel>
                                                                <TextField

                                                                    size="small"
                                                                    name={`address[${index}]`}
                                                                    type="text"
                                                                    value={conf.address}
                                                                    onChange={(e) => handleRegisterChange(index, e.target.value)}
                                                                    onBlur={formik.handleBlur}
                                                                    placeholder="40000"
                                                                // error={formik.touched.serials &&
                                                                //     formik.errors.serials &&
                                                                //     formik.errors.serials[index]}
                                                                // helperText={formik.touched.serials && formik.errors.serials[index]}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={11} md={3}>
                                                            <FormControl fullWidth
                                                            // error={formik.touched.configuration && Boolean(formik.errors.configuration)}
                                                            >
                                                                <FormLabel component="legend">Attribute {index + 1}</FormLabel>
                                                                <TextField

                                                                    size="small"
                                                                    name={`attribute[${index}]`}
                                                                    type="text"
                                                                    value={conf.attribute}
                                                                    onChange={(e) => handleAttributeChange(index, e.target.value)}
                                                                    onBlur={formik.handleBlur}
                                                                    placeholder="Voltage"
                                                                // error={formik.touched.serials &&
                                                                //     formik.errors.serials &&
                                                                //     formik.errors.serials[index]}
                                                                // helperText={formik.touched.serials && formik.errors.serials[index]}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={11} md={2}>
                                                            <FormControl fullWidth
                                                            // error={formik.touched.configuration && Boolean(formik.errors.configuration)}
                                                            >
                                                                <FormLabel component="legend">Unit {index + 1}</FormLabel>
                                                                <TextField

                                                                    size="small"
                                                                    name={`unit[${index}]`}
                                                                    type="text"
                                                                    value={conf.unit}
                                                                    onChange={(e) => handleUnitChange(index, e.target.value)}
                                                                    onBlur={formik.handleBlur}
                                                                    placeholder="V"
                                                                // error={formik.touched.serials &&
                                                                //     formik.errors.serials &&
                                                                //     formik.errors.serials[index]}
                                                                // helperText={formik.touched.serials && formik.errors.serials[index]}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={1} md={1}>
                                                            <IconButton
                                                                sx={{ mt: 3 }}
                                                                onClick={() => handleRemoveRegister(index)}
                                                                variant="outlined"
                                                                aria-label="Remove"
                                                                color="error"
                                                            >
                                                                <RemoveCircleRoundedIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </BoxItem>
                                            ))
                                        }
                                    </Stack>
                                </BoxItem>
                                <BoxItem>
                                    <IconButton variant="contained"
                                        onClick={handleAddRegister}
                                        aria-label="Add Feature"
                                        color="warning"
                                    >
                                        <AddCircleRoundedIcon />
                                    </IconButton>
                                </BoxItem>
                            </Stack>
                        </BoxItem>
                        <BoxItem>
                            <Stack
                                sx={{ mt: 5 }}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                spacing={3}
                            >
                                <BoxItem>
                                    <img src={ConfigurationBanner} width="150px" height="auto" alt="Add_illustration" />
                                </BoxItem>
                                <BoxItem>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Name"
                                        id="slaveName"
                                        name="slaveName"
                                        value={formik.values.slaveName}
                                        onChange={formik.handleChange}
                                        error={formik.touched.slaveName && Boolean(formik.errors.slaveName)}
                                        helperText={formik.touched.slaveName && formik.errors.slaveName}
                                    />
                                </BoxItem>
                                <BoxItem>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Manufacturer"
                                        id="manufacturer"
                                        name="manufacturer"
                                        value={formik.values.manufacturer}
                                        onChange={formik.handleChange}
                                        error={formik.touched.manufacturer && Boolean(formik.errors.manufacturer)}
                                        helperText={formik.touched.manufacturer && formik.errors.manufacturer}
                                    />
                                </BoxItem>
                                <BoxItem>
                                    <FormControl fullWidth>
                                        <FormLabel component="legend">Slave Type</FormLabel>
                                        <TextField
                                            size="small"
                                            select
                                            id='slaveTypeId'
                                            name='slaveTypeId'
                                            value={formik.values.slaveTypeId}
                                            onChange={formik.handleChange}
                                            error={formik.touched.slaveTypeId && Boolean(formik.errors.slaveTypeId)}
                                            helperText={formik.touched.slaveTypeId && formik.errors.slaveTypeId}
                                        >
                                            {
                                                slaveTypeList.map((t) => (
                                                    <MenuItem key={t.id} value={t.id}>
                                                        {t.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </FormControl>
                                </BoxItem>
                                <BoxItem>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        // disabled={true}
                                        fullWidth>
                                        Create Slave Device
                                    </Button>
                                </BoxItem>
                            </Stack>
                        </BoxItem>
                    </Stack>
                </form>

            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleClose}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    );
}
export default AddSlave;