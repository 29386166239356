import _ from "lodash";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material';
import indianOcean from './assets/main.theme';

// Pages
import MainDashboard from "./pages/Dashboard/Main.page";
import Login from "./pages/Forms/Login.page";
import VerifyUser from './pages/Verification/VerifyUser.page';
import { useSelector } from "react-redux";
const theme = createTheme(indianOcean);
function App() {

  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const authState = useSelector(state => state.auth);
  // console.log('auth state', authState);
  const currentLocation = useLocation();
  const from = _.get(currentLocation, "state.from.pathname", "/");

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Routes>
          <Route
            path="/verifylink"
            element={<VerifyUser />}
          />
          <Route
            path="/*"
            element={isAuthenticated ? <MainDashboard /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/login"
            element={!isAuthenticated ? <Login /> : <Navigate to={from} replace />}
          />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
