import { useState, useEffect } from "react";
import _ from 'lodash';
import axios from '../../utils/axios';
import { useNavigate, useParams } from "react-router-dom";
import {
    Box, Grid, IconButton, Button, Snackbar, Alert
} from '@mui/material';
// @mui-icon
import RemoveIcon from '@mui/icons-material/Remove';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
// Layouts
import DeviceMediaCard from "../../layouts/Device/DeviceMediaCard.layout";
import AddSlaveToDevice from '../../pages/Forms/Devices/AddSlave.modal';
import DataTable from '../../components/DataTable/Datatable.component';
import { actions, contextActions } from "../../components/DataTable/DTHelper.component";


// const columns = [
//     {
//         id: 1,
//         name: 'Slave Id',
//         selector: row => row['sdId'],
//         width: '100px',
//         maxWidth: '150px',
//         sortable: true,
//     },
//     {
//         name: 'Slave Name',
//         selector: row => row['name'].toUpperCase(),
//         sortable: true,
//     },
//     {
//         name: 'Manufacturer',
//         selector: row => row['manufacturer'].toUpperCase(),
//         sortable: true,
//     },
//     {
//         name: 'Type',
//         selector: row => row['type'].toUpperCase(),
//         sortable: true,
//     },
//     {
//         id: 'actions',
//         selector: row => row['sdId'],
//         cell: row => <IconButton color="error" onClick={_ => console.log(row['sdId'])}><RemoveIcon /></IconButton>
//     }
// ];



function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        // const i = d.slaveid.toLowerCase();
        const n = d.name.toLowerCase();
        const m = d.manufacturer.toLowerCase();
        const t = d.type.toLowerCase();
        return m.includes(query) || n.includes(query) || t.includes(query);
    }, data);
    return filteredData
}

const DeviceOverview = ({ profile, getData, setProfile }) => {
    const columns = [
        {
            id: 1,
            name: 'Slave Id',
            selector: row => row['sdId'],
            width: '100px',
            maxWidth: '150px',
            sortable: true,
        },
        {
            name: 'Slave Name',
            selector: row => row['name'].toUpperCase(),
            sortable: true,
        },
        {
            name: 'Manufacturer',
            selector: row => row['manufacturer'].toUpperCase(),
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row['type'].toUpperCase(),
            sortable: true,
        },
        {
            id: 'actions',
            name: 'Remove',
            selector: row => row['sdId'],
            cell: row => <IconButton color="error" onClick={_ => deleteAll(row['sdId'])}><RemoveIcon /></IconButton>
        }
    ];
    const [queryInput, setQueryInput] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState({});

    // const handleNavigate = _ => navigate('add');
    const handleQueryInput = (e) => {
        const query = e.target.value.trim().toLowerCase();
        setQueryInput(query);
    }



    // const handleChange = (s) => {
    //     setSelectedRows(s.selectedRows);
    // }
    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);
    const handleSnakbarClose = () => {
        if (!_.isEmpty(message) && message.status) getData();
        setSnackbarOpen(false);
    }
    const handleSnakbarOpen = () => setSnackbarOpen(true);

    const deleteAll = async (slaveid) => {
        if (window.confirm(`Are you sure you want to delete?`)) {
            const response = await axios.delete(`/devices/${profile.deviceId}/removeslave/${slaveid}`);
            const result = await response.data;
            setMessage(result);
        }
    }

    useEffect(() => {
        if (!_.isEmpty(message)) {
            if (open) {
                handleClose();
            }
            handleSnakbarOpen();
        }
    }, [message, open]);
    return (
        <Box sx={{ width: '100%' }}>
            <AddSlaveToDevice
                open={open}
                handleClose={handleClose}
                getData={getData}
                handleSnakbarOpen={handleSnakbarOpen}
                setMessage={setMessage}

            />
            {/* Snackbar notification */}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackbarOpen}
                onClose={handleSnakbarClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleSnakbarClose} sx={{ width: '100%' }}>
                    {
                        message.message ? message.message : null
                    }
                </Alert>
            </Snackbar>

            <Grid container spacing={5}>
                <Grid item xs={12} lg={9} xl={9} >
                    <DataTable
                        title={
                            <Button
                                size='small'
                                variant='outlined'
                                startIcon={<AddRoundedIcon />}
                                onClick={handleOpen}
                            >Add Slave</Button>
                        }
                        columns={columns}
                        data={SearchQuery(queryInput, profile.externalDevices)}
                        defaultSortField="Slave Id"
                        defaultSortFieldId={1}
                        actions={actions({
                            id: 'slavedevice-search-box',
                            handleChange: handleQueryInput,
                            getData
                        })}
                        selectableRows={false}
                    // selectableRowsNoSelectAll
                    // onSelectedRowsChange={handleChange}
                    // contextActions={contextActions(deleteAll)}
                    // clearSelectedRows={toggleCleared}

                    />
                </Grid>
                <Grid item={12} lg={3} xl={3} >
                    <DeviceMediaCard
                        deviceId={profile.deviceId}
                        name={profile.name}
                        status={profile.isActive}
                    />
                </Grid>
            </Grid>

        </Box>
    );
};
export default DeviceOverview;
