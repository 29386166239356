// src/redux/actions/authActions.js

import axios from '../../utils/axios';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILED, REFRESH_TOKEN, LOGOUT } from './types';

export const login = (credentials) => async (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    try {
        // Make an API request to authenticate
        const url = '/auth/signin';
        const response = await axios.post(url, credentials);

        // console.log('auth response', response.data);

        // Dispatch login success action with user data and token
        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                user: {
                    "email": response.data.email,
                    "name": response.data.name,
                    "id": response.data.id
                },
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken,
            },
        });
    } catch (error) {
        // Handle login failure
        dispatch({
            type: LOGIN_FAILED,
            payload: {
                status: false,
                message: error.response.data.message
            }
        })
    }
};


export const verify_token = (token) => async (dispatch) => {
    try {
        // Make an API request to authenticate
        const url = '/auth/verify';
        const response = await axios.post(url, token);

        // Dispatch login success action with user data and token
        dispatch({
            type: REFRESH_TOKEN,
            payload: {
                user: {
                    "email": response.data.email,
                    "name": response.data.name,
                    "id": response.data.id
                },
                token: response.data.accessToken,
            },
        });
    } catch (error) {
        // Handle login failure
        console.error('Login failed:', error);
    }
};

// Logout action
export const logout = () => (dispatch) => {
    // Dispatch logout action
    dispatch({ type: LOGOUT });
};