import PropTypes, { func } from 'prop-types';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { fShortenNumber } from '../../utils/format-number';

export default function WidgetSummary({ title, total, icon, color = 'primary', sx, ...others }) {
    return (
        <Card
            component={Stack}
            spacing={3}
            direction={"row"}
            sx={{
                px: 2, py: 3.33, borderRadius: 1.332, ...sx
            }}
            {...others}
        >

            {icon && <Box sx={{ width: 64, height: 64 }}>{icon}</Box>}
            <Stack spacing={0.5}>
                <Typography variant="h4">{fShortenNumber(total)}</Typography>
                <Typography variant="subtitle2" sx={{ color: 'text.disabled' }}>
                    {title}
                </Typography>
            </Stack>
        </Card>
    )
}