const permissionListType2 = {
    "devices": {
        "create": false,
        "view": false,
        "update": false,
        "delete": false,
        "assign": false,
        "unassign": false,
    },
    "slaves": {
        "create": false,
        "view": false,
        "update": false,
        "delete": false,
    },
    "users": {
        "invite": false,
        "view": false,
        "update": false,
        "delete": false,
        "viewInvites": false,
        "deleteInvites": false
    },
    "roles": {
        "create": false,
        "view": false,
        "update": false,
        "delete": false,
        "viewPermissions": false,
        "assign": false,
        "unassign": false
    },
    "customers": {
        "create": false,
        "view": false,
        "update": false,
        "delete": false
    },
};
export default permissionListType2;