import { Routes, Route } from "react-router-dom";

import DeviceDetail from "./DeviceDetail.page";
import DeviceList from "./DeviceList.page";
import AddDevice from "../Forms/Devices/AddDevice.page";
export default function DevicesMain() {
    return (
        <Routes>
            <Route path="/" element={<DeviceList />} />
            <Route path="/:id" element={<DeviceDetail />} />
            <Route path='/add' element={<AddDevice />} />
        </Routes>
    );
}

