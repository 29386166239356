import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";


const NavigateTo = ({ to, componentIcon }) => {
    const navigate = useNavigate();
    return (
        <IconButton onClick={() => navigate(to)}>
            {/* <ArticleRoundedIcon /> */}
            {componentIcon}
        </IconButton>
    );
}
export default NavigateTo;