import React, { useState, useEffect } from 'react';
import axios from "../../utils/axios"
import dayjs from 'dayjs';
import { Link as RouterLink, } from 'react-router-dom';
import {
    Box, Typography, Link,
    Grid,
    IconButton,
    Button, FormControl, InputLabel, MenuItem, Select, Snackbar, Alert
} from '@mui/material';
// Mui Icons
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from '@mui/icons-material/Sync';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// Custom components
// import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import DataTable from "../../components/DataTable/Datatable.component";
import Search from "../../components/Search/Search.component";
import CreateDataTable from './CreateDataTable.section';
// import EditDataTable from './EditDataTable.section';
import ConfirmDelete from "../../layouts/Delete.layout"
const maxCols = 30;
const dataSize = 25;

const cols = [];
for (let i = 0; i < maxCols; i++) {
    // cols.push({ key: `value${i + 1}`, name: `Value ${i + 1}` });
    cols.push({ columnId: `value${i + 1}`, width: 150 });
}
const getData = () => {
    const d = {};
    for (let i of cols) { d[i.columnId] = Math.floor(Math.random() * 100) }
    d['timestamp'] = dayjs().format('YYYY-MM-DD HH:mm:ss');
    return d;
}
const data = [...new Array(dataSize)].map(getData);
cols.push({ key: 'timestamp', name: 'Timestamp' });

// // Example data options
// const options = [
//     {
//         label: 'Option 1',
//         data: dataResult,
//         columns: cols,
//     },
//     {
//         label: 'Option 2',
//         data: [
//             { timestamp: '2024-08-01 14:00', valueA: 50, valueB: 60 },
//             { timestamp: '2024-08-01 15:00', valueA: 70, valueB: 80 },
//             // more data
//         ],
//         columns: ['valueA', 'valueB'],
//     },
//     // add more options as needed
// ];

const actions = ({ getData, handleChange, handleNavigate }) => (
    <Grid container
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={1}
    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id={"search-modules"} placeholder={"Search..."} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button
                variant="contained"
                size="small"
                sx={{ float: 'right' }}
                startIcon={<AddIcon />}
                onClick={handleNavigate}
            >Create</Button>
        </Grid>
    </Grid>
)
function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        // const des = d.description.toLowerCase();
        // return n.includes(query) || des.includes(query);
        return n.includes(query);
    }, data);
    return filteredData;
}
export default function DataTableView() {
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [createDialogState, setCreateDialogState] = useState(false);
    // const [editDialogState, setEditDialogState] = useState(false);
    const [deleteDialogState, setDeleteDialogState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [snakbarState, setSnakbarState] = useState(false);
    const [message, setMessage] = useState(null);
    // const [selectedItem, setSelectedItem] = useState(null);
    // const []
    const columns = [
        {
            name: "Actions",
            cell: row => (
                <>
                    <IconButton aria-label="delete" onClick={() => handleDeleteDialogOpen(row['_id'])}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                    {/* <IconButton sx={{ mx: 1 }} aria-label="edit" onClick={() => handleEditDialogOpen(row['_id'])}>
                        <EditIcon fontSize="small" />
                    </IconButton> */}
                    <Link component={RouterLink} to={`/insights/${row['slug']}`} underline="hover">
                        {'View'}
                    </Link>
                </>
            ),
            maxWidth: '130px'
        },
        {
            name: "Name",
            selector: row => row['name'],
            sortable: true
        },
        {
            name: "No. Of Columns",
            selector: row => row['columnKeysLength'],
            sortable: true
        },
        {
            name: "Created On",
            //selector: row => row['createdAt'],
            cell: row => dayjs(row['createdAt']).format('lll'),
            sortable: true
        }
    ];
    const handleQueryInput = (e) => {
        setSearchQuery(e.target.value.trim().toLowerCase());
    }
    // handles for create 
    const handleCreateDialogOpen = () => setCreateDialogState(true);
    const handleCreateDialogClose = () => {
        setCreateDialogState(false)
    };

    // const handleEditDialogOpen = (id) => {
    //     setSelectedItem(id);
    //     setEditDialogState(true);
    // }
    // const handleEditDialogClose = () => {
    //     setSelectedItem(null);
    //     setEditDialogState(false);
    // }

    const handleDeleteDialogOpen = (id) => {
        setSelectedItem(id);
        setDeleteDialogState(true);
    }
    const handleDeleteDialogClose = (id) => {
        setSelectedItem(null);
        setDeleteDialogState(false);
    }
    const handleSnakbarClose = () => {
        setMessage(null);
        setSnakbarState(false);
    }
    const handleSnakbarOpen = () => setSnakbarState(true);
    const getData = () => {
        setLoading(true);
        axios.get('/monitor/tables')
            .then(res => {
                setData(res.data);
            })
            .catch(err => {
                console.error("An error occurred while fetching data", err);
            }).finally(() => {
                setLoading(false);
            })
    }
    const handleDelete = () => {
        axios.delete(`/monitor/deletetable/${selectedItem}`)
            .then(res => {
                if (res.status === 200) {
                    setMessage(res.data);
                    handleSnakbarOpen();
                    getData();
                    handleDeleteDialogClose();
                }
            }).catch(err => {
                console.error('An error occurred while deleting resource', err);
                setMessage({
                    "status": false,
                    "message": "Something went wrong while delete operation"
                });
                handleSnakbarOpen();
                handleDeleteDialogClose();
            })
    }
    useEffect(() => {
        getData();
    }, []);
    return (
        // <Box>
        //     <SectionHeader heading=" Data Insights" />
        <Box
        // display="flex"
        >
            <DataTable
                title={'Data Tables'}
                columns={columns}
                data={SearchQuery(searchQuery, data)}
                actions={actions({ getData, handleChange: handleQueryInput, handleNavigate: handleCreateDialogOpen })}
                defaultSortField="name"
                // defaultSortField={1}
                selectableRows={false}
                selectableRowsHighlight={false}
                progressPending={loading}
            />
            <CreateDataTable
                open={createDialogState}
                handleClose={handleCreateDialogClose}
                handleSnakbarOpen={handleSnakbarClose}
                refreshData={getData}
                setMessage={setMessage}
            />
            {/* <EditDataTable
                open={editDialogState}
                handleClose={handleEditDialogClose}
                handleSnakbarOpen={handleSnakbarClose}
                refreshData={getData}
                setMessage={setMessage}
                selectedItem={selectedItem}
            /> */}
            <ConfirmDelete
                open={deleteDialogState}
                handleClose={handleDeleteDialogClose}
                handleDelete={handleDelete}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snakbarState}
                onClose={handleSnakbarClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message && message?.status) ? "success" : "error"} onClose={handleSnakbarClose} sx={{ width: '100%' }}>
                    {
                        message && message?.message ? message.message : null
                    }
                </Alert>
            </Snackbar>
        </Box>
        // </Box>
    );
};


