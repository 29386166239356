import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button
} from "@mui/material";
export default function ConfirmDelete({
    open, handleClose, handleDelete
}) {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>{"Confirm Delete"}</DialogTitle>
            <DialogContent>
                <DialogContentText >
                    Are you sure you want to delete this item?
                </DialogContentText>
            </DialogContent>
            <DialogActions center>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button color="error" onClick={handleDelete} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
