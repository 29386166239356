import Box from "@mui/material/Box";
import { Routes, Route } from "react-router-dom";
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import DataTableView from "../../sections/DataTable/DataTable.section";
import Visualization from "../../sections/DataTable/Visualization.section";
export default function Insights() {

    return (
        <Box>

            <SectionHeader heading=" Data Insights" />
            <Routes>
                <Route path="/" element={<DataTableView />} />
                <Route path="/:name" element={<Visualization />} />
            </Routes>
        </Box>
    );
}