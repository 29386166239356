import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import axios from '../../utils/axios';
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';

// MUI Components
import {
    Box, IconButton,
    // Typography, IconButton, Grid, Button, Snackbar, Alert
} from "@mui/material";
// @mui-icons
import EditIcon from '@mui/icons-material/Edit';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
// custom components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import DataTable from "../../components/DataTable/Datatable.component";
import NavigateTo from "../../components/Common/NavigateTo.component";
import { actions, contextActions } from '../../components/DataTable/DTHelper.component'
import AddButton from '../../components/Common/AddButton.comonent';
import ScrollDialog from "./QuickView.page";
// Data table configuration


function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        const t = d.type.toLowerCase();
        const m = d.manufacturer.toLowerCase();
        return n.includes(query) || m.includes(query) || t.includes(query);
    }, data);
    return filteredData;
}

const SlaveList = () => {

    const columns = [
        {
            name: "Name",
            selector: row => _.upperCase(row['name']),
            sortable: true,
            left: true
        },
        {
            name: "Manufacturer",
            selector: row => _.upperCase(row['manufacturer']),
            sortable: true,
            left: true
        },
        {
            name: "Type",
            selector: row => _.upperCase(row['type']),
            sortable: true,
            left: true
        },
        {
            name: "Created At",
            selector: row => dayjs(row.createdAt).format('lll'),
            sortable: true,

        },
        {
            id: 'actions',
            width: '40px',
            maxWidth: '100px',
            right: true,
            cell: row => <IconButton onClick={() => handleQVOpen(row['id'])}><ViewHeadlineIcon /></IconButton>
        },
        {
            id: 'edit',
            right: true,
            width: '40px',
            maxWidth: '100px',
            // center: true,
            cell: row => <NavigateTo to={`${row['id']}/edit`} componentIcon={<EditIcon />} />
        }
    ];
    dayjs.extend(localizedFormat);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [sdid, setSDID] = useState(null);
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [queryInput, setQueryInput] = useState("");
    const [message, setMessage] = useState(false);
    const [openQV, setOpenQV] = useState(false);
    const handleNavigate = () => navigate('add');

    const handleSnakbarClose = () => {
        setMessage(false);
    }

    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }

    const handleQVOpen = (sdId) => {
        console.log('set sdid', sdId);
        setSDID(sdId);
        setOpenQV(true);
    };
    const handleQVClose = () => setOpenQV(false);

    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('/slaves');
        const result = await response.data;
        setData(result);
    }

    const deleteAll = () => {
        // const rows = selectedRows.map(r => r.id);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, selectedRows, 'id'));
        }
    }

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (openQV) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openQV]);
    useEffect(() => { getData(); }, []);
    useEffect(() => {
        if (loading) setLoading(false);
    }, [data]);
    return (
        <Box>
            <SectionHeader heading="Slave Devices" />
            <Box sx={{ mt: 1 }}>
                <DataTable
                    title={<AddButton handleNavigate={handleNavigate} buttonText={'Slave'} />}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField="name"
                    defaultSortFieldId={1}
                    actions={actions({ id: 'slave-search-box', handleChange: handleQueryInput, getData })}
                    contextActions={contextActions(deleteAll)}
                    onSelectedRowsChange={handleChange}
                    selectableRowsNoSelectAll
                    clearSelectedRows={toggleCleared}
                    // onRowClicked={handleRowClicked}
                    progressPending={loading}
                />
            </Box>
            <ScrollDialog
                id={sdid}
                openQV={openQV}
                handleQVClose={handleQVClose}
                descriptionElementRef={descriptionElementRef}
            />
        </Box>
    );
}
export default SlaveList;

