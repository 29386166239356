import axios from '../../../utils/axios';
import Grid from '@mui/material/Unstable_Grid2';
import Iconify from '../../../components/Iconify/iconify';
import Loading from '../../../components/Common/Loading.component';
import WidgetSummary from '../SummaryCard';
import { useEffect, useState } from 'react';

export default function Elements() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        setLoading(true);
        const response = await axios.get('/monitor/elements');
        const result = response.data;
        setData(result)
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Grid xs={12} sm={6} md={3}>
                <WidgetSummary
                    title="Devices"
                    total={
                        loading ? <Loading /> : data?.data?.totalDevices || '-'
                    }
                    color="success"
                    icon={<Iconify icon="ri:router-fill" color="#7C4DFF" width={64} />}
                    elevation={6}
                />
            </Grid>
            <Grid xs={12} sm={6} md={3}>
                <WidgetSummary
                    title="Slave Devices"
                    total={loading ? <Loading /> : data?.data?.totalSlaves || '-'}
                    color="success"
                    icon={<Iconify icon="ic:round-sensors" color="#E03997" width={64} />}
                    elevation={6}
                />
            </Grid>
            <Grid xs={12} sm={6} md={3}>
                <WidgetSummary
                    title="Users"
                    total={loading ? <Loading /> : data?.data?.totalUsers || '-'}
                    color="success"
                    icon={<Iconify icon="mdi:users" color="#D9B03B" width={64} />}
                    elevation={6}
                />
            </Grid>
        </>
    )
}