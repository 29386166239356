
// @mui
import { Grid, IconButton } from '@mui/material';
// @mui-icons
import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from "@mui/icons-material/Delete";
// Custom component
import Search from '../Search/Search.component';

const actions = ({ id, handleChange, getData }) => (
    <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-end"
        spacing={1}
    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id={id} placeholder={"Search..."} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
    </Grid>
);

const contextActions = deleteHandler => (
    <IconButton onClick={deleteHandler}>
        <DeleteIcon />
    </IconButton>
);

export {
    actions, contextActions
}

