import { faker } from '@faker-js/faker';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import WidgetSummary from '../SummaryCard';
import Iconify from '../../../components/Iconify/iconify';
import DailyCounts from '../DailyCounts';
import Elements from './elements';
export default function AppView() {

    return (
        <Container maxWidth='xl' sx={{ mt: 5 }}>
            {/* <Typography variant="h4" sx={{ mb: 5 }}>

            </Typography> */}

            <Grid container spacing={3} >
                {/* <Grid xs={12} sm={6} md={3}>
                    <WidgetSummary
                        title="Online Devices"
                        total={714000}
                        color="success"
                        icon={<Iconify icon="heroicons-outline:status-online" color="#28B25F" width={64} />}
                        elevation={6}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <WidgetSummary
                        title="Offline Devices"
                        total={714000}
                        color="success"
                        icon={<Iconify icon="heroicons-outline:status-offline" color="#DF3E30" width={64} />}
                        elevation={6}
                    />
                </Grid> */}
                <Elements />

                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                    <DailyCounts
                        title={"Device Activity"}
                        subheader={"Number of data sent daily."}
                    />
                </Grid>

            </Grid>
        </Container>
    )
}