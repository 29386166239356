import { Routes, Route } from "react-router-dom";

// import DeviceProfile from "./DeviceProfile.page";
import UserList from "./UserList.page";
import AddUser from "../Forms/Users/AddUser.page";
export default function UserMain() {
    return (
        <Routes>
            <Route path="/" element={<UserList />} />
            {/* <Route path="/:serial" element={<DeviceProfile />} /> */}
            <Route path='/add' element={<AddUser />} />
        </Routes>
    );
}

