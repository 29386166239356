import { styled } from '@mui/system';
import axios from '../../utils/axios';
import { useSearchParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import backgroundImage from '../../assets/Atra-Side-Banner.png';
import logo from '../../assets/emblems/Emblem@0.5x.png';
import { useEffect, useState } from 'react';

const BackgroundContainer = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    display: 'block',
    overflow: 'hidden',
}));


const FormContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ImageContainer = styled('div')(({ theme }) => ({
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const Logo = styled('img')(({ theme }) => ({
    width: '6em',
    marginBottom: theme.spacing(3),
}));


const GradientTypography = styled(Typography)(({ theme }) => ({
    background: `-webkit-linear-gradient(-65deg, #ABD9E6 5%, #48A89D 27%, #134E5E 95%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    paddingBottom: theme.spacing(6),
}));
const VerifyUser = () => {
    const [loading, setLoading] = useState("nothing");
    const [sp, setSP] = useSearchParams();
    // console.log('code', sp.get('code'));
    // console.log('token', sp.get('token'));

    const verifyLink = async () => {
        setLoading('requested');
        try {
            const response = await axios.post('/', {
                code: sp.get("code"),
                token: sp.get("token")
            });
            const result = await response.data;
            setLoading('done');
        } catch (e) {
            console.error('verification error', e);
            setLoading('done');
        }
    }

    useEffect(() => {
        if (sp.get('code') && sp.get('token')) {
            verifyLink();
        }
    }, [])

    return (
        <BackgroundContainer>
            <Grid container>
                <Grid item xs={12} md={12} lg={5} xl={5}>
                    <FormContainer>
                        <Logo src={logo} alt="Logo" />
                        <GradientTypography component="div" variant="h5" align="center">
                            {`Verification`}
                        </GradientTypography>
                    </FormContainer>
                </Grid>
                <Grid item xs={12} md={7} sx={{ padding: 0, margin: '0px' }}>
                    <ImageContainer />
                </Grid>
            </Grid>
        </BackgroundContainer>
    );
};

export default VerifyUser;