// src/redux/reducers/authReducer.js

import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT } from '../actions/types';
import { jwtDecode } from "jwt-decode";

import Cookies from 'js-cookie';
const token = localStorage.getItem('token');

const initialState = {
    loading: false,
    isAuthenticated: false,
    user: null,
    error: null
};
// Dispatch login success action if a token exists

if (token && token !== 'undefined') {
    const decodedToken = jwtDecode(token);
    initialState.isAuthenticated = true;
    initialState.user = decodedToken;
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_SUCCESS:
            // Store the token in localStorage
            localStorage.setItem('token', action.payload.accessToken);
            localStorage.setItem('refreshToken', action.payload.refreshToken);
            // Cookies.set('refreshToken', action.payload.refreshToken, { httpOnly: true, sameSite: 'strict' });

            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload.user,

            };
        case LOGOUT:
            // Remove the token from localStorage
            localStorage.clear();

            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export default authReducer;
