// @mui
import {
    Box, Grid, Card,
    Chip, CardContent,
    Typography,
    Divider
    // Avatar, 
    // Link, 
} from '@mui/material'
import { styled } from '@mui/material/styles';
// @mui-icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

// Custom Components
// import { BoxItem } from '../../components/Common/Item.component';
const StyledCardMedia = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)',
});
const StyledCover = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',

});
const DeviceMediaCard = ({ deviceId, name, status }) => {

    return (
        <Box sx={{ width: '100%' }}>
            <Card
                sx={{
                    position: 'relative',
                    backgroundImage: 'linear-gradient(to bottom left, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)',
                    color: 'white'
                }}
                elevation={3}
            >
                {/* <StyledCardMedia>
                    <StyledCover alt={title} src={cover} />
                </StyledCardMedia> */}
                {/* <Divider orientation='vertical' variant='fullWidth' 
                flexItem 
                /> */}
                <CardContent sx={{ pt: 4, backgroundColor: 'rgba(255, 255, 255, 0.2)' }}>
                    <Grid
                        container spacing={3}
                    >
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant='subtitle1'>Device Id</Typography>
                                <Typography variant='caption'>{deviceId ? deviceId : '-'}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant='subtitle1'>Name</Typography>
                                <Typography variant='caption'>{name ? name : '-'}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant='subtitle1'>Online Status</Typography>
                                {
                                    <Box sx={{ display: "flex", alignItems: "center", my: '0.5em' }}>
                                        {(status) ?
                                            <Chip label="Online" color="success" size="small" icon={<CheckCircleIcon />} />
                                            :
                                            <Chip label="Offline" color="error" size="small" icon={<ErrorIcon />} />}
                                    </Box>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
};
export default DeviceMediaCard;
