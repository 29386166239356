import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import axios from '../../../utils/axios';
// @mui
import {
    Box, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, ListItemText, ListItemIcon, Select,
    DialogContentText, TextField, FormControl, FormControlLabel, FormLabel, MenuItem
} from "@mui/material";
import { green, pink, deepPurple, amber } from '@mui/material/colors';

const AddSlaveToDevice = ({ open, handleClose, getData, setMessage, handleSnakbarOpen }) => {
    const { id } = useParams();
    const [slaveList, setSlaveList] = useState([]);

    const getSlaveData = async () => {
        const response = await axios.get('/slaves');
        const result = await response.data;
        setSlaveList(result);
    }

    const postData = async (data) => {
        const response = await axios.put(`devices/${id}/addslave`, data);
        const result = await response.data;
        setMessage(result);
    }

    const formik = useFormik({
        initialValues: {
            id: '', slaveId: ''
        },
        onSubmit: (v) => {
            postData(v);
        }
    })

    useEffect(() => {
        if (open)
            getSlaveData();
        return () => {
            setSlaveList([]);
            formik.resetForm();
        }
    }, [open]);


    return (
        <Box>
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={handleClose}

            >
                <form
                    onSubmit={formik.handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >

                    <DialogTitle>Add Slave(s) to Device</DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth size="small">
                            <FormLabel component="legend">Select Slave</FormLabel>
                            <Select
                                // size="small"
                                // select
                                id="slaveId"
                                name="slaveId"
                                value={formik.values.slaveId}
                                onChange={formik.handleChange}
                                error={formik.touched.slaveId && Boolean(formik.errors.slaveId)}
                                helperText={formik.touched.slaveId && formik.errors.slaveId}
                            >
                                {
                                    slaveList.map(slv => (
                                        <MenuItem key={slv.id} value={slv.id} dense>{`${slv.name} - ${slv.manufacturer}`}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="standard"
                            id="id"
                            name="id"
                            label="Id"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.id && Boolean(formik.errors.id)}
                            helperText={formik.touched.id && formik.errors.id}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
};

export default AddSlaveToDevice;