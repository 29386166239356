import { useState, useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import _ from "lodash";
import axios from "../../utils/axios";
import { Box, Button, Container } from "@mui/material";
import DataGrid from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { exportToCsv } from "../../helper/Utility.helper";
import Loading from "../../components/Common/Loading.component";

const convert2Cols = (arr) => {
    const c = [];
    c.push({
        key: 'duration',
        name: 'Time (24Hrs)',
        frozen: true,
        width: 100,

    });
    for (let obj of arr) {
        let heading = obj['label'];
        if (obj.unit !== "" && obj.unit !== "-") {
            heading = `${heading} (${obj.unit})`;
        }
        c.push({ key: obj['key'], name: heading, width: 90 });
    }

    return c;
};


const transformHour2Range = (rows) => {
    return rows.map(r => ({ ...r, 'duration': `${r.hour}:00` }));
};


export default function Visualization() {
    const { name } = useParams();
    const [loading, setLoading] = useState(false);
    const [cols, setCols] = useState(null);
    const [rows, setRows] = useState(null);
    const [startSelectedDate, setStartSelectedDate] = useState(new Date(dayjs().subtract(1, 'day').toDate()));
    const [endSelectedDate, setEndSelectedDate] = useState(new Date());
    const getData = (name, startdate, enddate) => {
        setLoading(true);
        const formattedStartDate = dayjs(startdate).format('YYYY-MM-DD HH:mm');
        const formattedEndDate = dayjs(enddate).format('YYYY-MM-DD HH:mm');
        axios.get('/monitor/tabledata', {
            params: { slug: name, startDate: formattedStartDate, endDate: formattedEndDate }
        })
            .then(res => {
                const c = convert2Cols(res.data.columnsConf);
                if (res.data.data && res.data.data.length > 0) {
                    const r = transformHour2Range(res.data.data);
                    const totalRow = {
                        ...res.data.sum[0],
                        'duration': 'Total',
                        'hour': '-',
                        'month': '-',
                        'year': '-'
                    };
                    const averageRow = {
                        ...res.data.avg[0],
                        'duration': 'Average',
                        'hour': '-',
                        'month': '-',
                        'year': '-'
                    };
                    r.push(totalRow);
                    r.push(averageRow);
                    setRows(r);
                } else {
                    setRows([]);
                }
                setCols(c);
            })
            .catch(err => {
                console.error('An error occurred while fetching data', err);
            })
            .finally(() => setLoading(false));
    };

    const handleStartDateChange = (newDate) => {
        setStartSelectedDate(newDate);
    };

    const handleEndDateChange = (endDate) => {
        setEndSelectedDate(endDate);
    }

    const handleSubmit = () => {
        getData(name, startSelectedDate, endSelectedDate);
    };

    const exportData = () => {
        // console.log(JSON.stringify(cols, null, 2));
        // console.log(JSON.stringify(rows, null, 2));
        const c = cols.map(k => k.key);
        const d = [];
        let ttl = [];
        let avg = [];
        for (let obj of rows) {
            if (obj.duration === 'Total') {
                ttl = obj;
            } else if (obj.duration === 'Average') {
                avg = obj;
            }
            else {
                const r = c.map(k => obj[k]);
                d.push(r);
            }
        }
        d.push(c.map(k => ttl[k]));
        d.push(c.map(k => avg[k]));
        // console.log(d);
        const csvContent = [
            cols.map(k => k.name).join(','),
            ...d.map(r => r.join(','))
        ].join('\n');
        // console.log(csvContent);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', 'data.csv');
        link.style.visibility = 'hidden';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // exportToCsv('data.csv', rows);
    };

    useEffect(() => {
        getData(name, startSelectedDate, endSelectedDate);
    }, [name, setStartSelectedDate, endSelectedDate]);

    return (
        <Box>
            <Container maxWidth="xl">
                <Box
                    sx={{
                        my: 3,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <DatePicker
                        selected={startSelectedDate}
                        onChange={handleStartDateChange}
                        dateFormat="yyyy-MM-dd HH"
                        className="form-control"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={60}
                    // 1-hour intervals
                    // minDate={dayjs(startSelectedDate).subtract(2, 'day').toDate()} // 2 days back
                    // maxDate={dayjs().toDate()}
                    />
                    {'to'}
                    <DatePicker
                        selected={endSelectedDate}
                        onChange={handleEndDateChange}
                        dateFormat="yyyy-MM-dd HH"
                        className="form-control"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={60} // 1-hour intervals
                    // minDate={dayjs(startSelectedDate).subtract(2, 'day').toDate()} // 2 days back
                    // maxDate={dayjs().toDate()}
                    />
                    <Button size="small" variant="contained" onClick={handleSubmit}>
                        Fetch Data
                    </Button>
                    {
                        (!_.isNull(cols) && !_.isEmpty(cols) && !_.isNull(rows) && !_.isEmpty(rows)) ?
                            <Button size="small" variant="contained" onClick={exportData}>
                                Export csv
                            </Button>
                            : null
                    }
                </Box>

                {/* <Box style={{ height: 400, width: '100%', overflowX: 'auto' }}> */}
                {
                    loading ? <Loading /> :
                        (!_.isNull(cols) && !_.isEmpty(cols) && !_.isNull(rows) && !_.isEmpty(rows)) ?
                            <DataGrid
                                sx={{
                                    minWidth: 1500,
                                    height: 900,
                                    '& .rdg': {
                                        overflowX: 'auto',
                                        overflowY: 'auto',
                                    },
                                    '& .rdg-cell': {
                                        whiteSpace: 'normal', textWrap: 'wrap' // Allow text wrapping in cells
                                    },
                                    '& .rdg-header-row': {
                                        whiteSpace: 'normal', // Allow text wrapping in header cells
                                        textAlign: 'center',
                                        textWrap: 'wrap' // Center text in header cells
                                    }
                                }}
                                columns={cols}
                                rows={rows}
                                className="fill-grid"
                                direction={'ltr'}
                            />
                            : "data not found"
                }
                {/* </Box> */}
            </Container>
        </Box>
    );
}
