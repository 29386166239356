import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import axios from '../../utils/axios';
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';

// MUI Components
import {
    Box, Snackbar, Alert
} from "@mui/material";
// @mui-icons
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
// import EditIcon from '@mui/icons-material/Edit';

// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import NavigateTo from '../../components/Common/NavigateTo.component';
import DataTable from "../../components/DataTable/Datatable.component";

import { actions, contextActions } from '../../components/DataTable/DTHelper.component'
import AddButton from '../../components/Common/AddButton.comonent';

// Data table configuration
const columns = [
    {
        name: "Role",
        selector: row => row['name'],
        sortable: true,
        // left: true
    },
    // {
    //     name: "Permissions",
    //     selector: row => row['permissions'],
    //     sortable: true,
    //     // left: true
    // },
    {
        name: "Created At",
        selector: row => row['createdAt'],
        sortable: true,
        cell: row => dayjs(row.createdAt).format('lll'),
        // right: true
    },
    {
        id: "actions",
        // name: "Actions",
        selector: row => row['_id'],
        center: true,
        cell: row => <NavigateTo to={row['_id']} componentIcon={<ArticleRoundedIcon />} />
    },

];

function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        // const l = d.email.toLowerCase();
        // return n.includes(query) || l.includes(query);
        return n.includes(query);
    }, data);
    return filteredData
}
const RoleList = () => {
    dayjs.extend(localizedFormat);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [queryInput, setQueryInput] = useState("");
    const [message, setMessage] = useState(false);
    const handleNavigate = () => navigate('add');

    const handleSnakbarClose = () => {
        setMessage(false);
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    const deleteAll = () => {
        // const rows = selectedRows.map(r => r.id);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, selectedRows, '_id'));
        }
    }

    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('/roles');
        const result = await response.data;
        setData(result.data);

    }

    useEffect(() => { getData(); }, []);
    useEffect(() => {
        if (loading) setLoading(false);
    }, [data]);
    return (
        <Box>
            <SectionHeader heading="Roles" />
            <Box sx={{ mt: 1 }}>
                <DataTable
                    title={<AddButton handleNavigate={handleNavigate} buttonText={'Role'} />}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField="role"
                    defaultSortFieldId={1}
                    actions={actions({ id: 'role-search-box', handleChange: handleQueryInput, getData })}
                    contextActions={contextActions(deleteAll)}
                    onSelectedRowsChange={handleChange}
                    selectableRowsNoSelectAll
                    clearSelectedRows={toggleCleared}
                    // onRowClicked={handleRowClicked}
                    progressPending={loading}
                />
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={message}
                onClose={handleSnakbarClose}
                autoHideDuration={4000}
            >
                <Alert severity="success" onClose={handleSnakbarClose}>
                    {
                        "Added successfully"
                    }
                </Alert>
            </Snackbar>
        </Box>
    );
};
export default RoleList;