import { useEffect, useState } from "react";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';
import axios from '../../utils/axios';
import { Box, Grid, List, Container, ListItem, ListItemText, ListItemIcon, ListItemButton, Typography, Avatar } from "@mui/material";
import { deepOrange } from "@mui/material/colors";

import DataTable from '../../components/DataTable/Datatable.component';
const SlaveData = ({ profile }) => {
    dayjs.extend(localizedFormat);
    const [loading, setLoading] = useState(false);
    const [cols, setCols] = useState(null);
    const [data, setData] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const getDataOf = async (id, sdid) => {
        try {
            setLoading(true);
            const response = await axios.get('/slaves/getslavedata', {
                params: { "id": id, "slaveId": sdid, "deviceId": profile.deviceId }
            });
            const result = await response.data;
            setData(result.data);
            const c = castColumns(result.columns);
            setCols(c);
            setLoading(false);
            setDataLoaded(true);
        } catch (error) {
            // Handle error
            console.log("Error", error);
            setLoading(false);
            setDataLoaded(true);
        }
    }

    const castColumns = (c) => {
        if (!c) return [];
        const newArr = [];
        const timestampColumnObject = c.filter(f => f.selector === 'timestamp')[0];
        newArr.push({
            id: timestampColumnObject.id,
            name: timestampColumnObject.name.toUpperCase(),
            cell: row => _.has(row, timestampColumnObject.selector) ? <div className="frozen-column">{dayjs(row[timestampColumnObject.selector]).format('YYYY-MM-DD HH:mm:ss')}</div> : 'N/A',
            width: '180px'
        });

        for (let obj of c) {
            if (obj.selector !== 'timestamp') {
                newArr.push({
                    id: obj.id,
                    name: obj.name,
                    cell: row => _.has(row, obj.selector) ? row[obj.selector] : 'N/A'
                })
            }
        }
        // return c.filter(f=> f.selector !== 'timestamp').map((v, i) => ({
        //         id: v.id,
        //         name: v.name,
        //         cell: row => _.has(row, v.selector) ? (v.selector === 'timestamp') ? dayjs(row[v.selector]).format('lll') : row[v.selector] : 'N/A',
        //         width: '120px',
        //     }))
        return newArr;
    }

    const handleListItemClick = (id, sdid, index) => {
        setSelectedIndex(index);
        getDataOf(id, sdid);
    };

    // on mount
    useEffect(() => {
        if (profile.externalDevices.length > 0) {
            // console.log('mounted');
            getDataOf(profile.externalDevices[0].id, profile.externalDevices[0].sdId);
        }
        // on unmount
        return () => {
            // console.log('unmounted');
        }
    }, []);


    return (
        <Box>
            <Grid
                container spacing={1}
            >
                <Grid item xs={12} md={2} lg={2} xl={2}>
                    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <Box>
                            <Typography variant="h5">Slave Devices</Typography>
                        </Box>
                        <List component="nav" aria-label="Slave device Data List">
                            {
                                profile &&
                                profile.externalDevices.map((item, index) => (
                                    <ListItemButton
                                        key={item.sdId} disablePadding
                                        selected={selectedIndex === index}
                                        onClick={() => handleListItemClick(item.id, item.sdId, index)}
                                    >

                                        <ListItemIcon>
                                            <Avatar
                                                variant="rounded"
                                                sx={{ bgcolor: deepOrange[500], width: 24, height: 24 }}
                                                alt={`${item.name}`}
                                            >{item.sdId}</Avatar>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item.name.toUpperCase()}
                                        />
                                    </ListItemButton>
                                ))}
                        </List>
                    </Box>
                </Grid>
                <Grid item xs={12} md={10} lg={10} xl={10}>
                    {/* <Box
                        sx={{

                            // width: ,
                            width: '100%',
                            // maxWidth: '80%',
                            overflow: 'auto',
                            height: 'calc(100vh - 180px)'
                        }}
                    > */}
                    <Container maxWidth="xl">
                        {
                            dataLoaded && cols !== null && data !== null ?
                                (<DataTable
                                    // title={"Slave Data"}
                                    columns={cols}
                                    data={data}
                                    defaultSortField="Register"
                                    defaultSortFieldId={1}
                                    selectableRows={false}
                                    progressPending={loading}
                                />)
                                : null
                        }
                        {/* </Box> */}
                    </Container>
                </Grid>
            </Grid>
        </Box >
    )
}

export default SlaveData;