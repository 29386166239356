import { Button } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
const AddButton = ({ handleNavigate, buttonText }) => (
    <Button
        variant="contained"
        size="small"
        startIcon={<AddRoundedIcon />}
        onClick={handleNavigate}
    >
        {buttonText}
    </Button>
);
export default AddButton;