import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from '../../../utils/axios';

// Mui Components
import { styled } from '@mui/material';
import {
    Box, Button, Stack, TextField, Snackbar, Alert
} from '@mui/material';

import { Formik, Form, Field } from 'formik';

// Custom Components
import SectionHeader from '../../../components/SectionHeader/SectionHeader.component';
import { BoxItem } from '../../../components/Common/Item.component';


// Validation
const validationSchema = Yup.object().shape({
    name: Yup.string('Must be string').required('Name is required'),
    email: Yup.string('Must be string').required('Email is required')
})

const AddUser = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
        navigate('/users', { replace: true });
    }
    const postData = async (data) => {
        const response = await axios.post('/users', data);
        const result = await response.data;
        // console.log('Install response', response.status);
        setOpen(true);
        setMessage(result);
        if (result.status) {
            setTimeout(() => {
                navigate('/users', { replace: true });
            }, 4000);
        }
    }
    const handleSubmit = (v) => {
        // alert(JSON.stringify(v), null, 2);
        postData(v);
    }
    return (
        <Box>
            <SectionHeader heading="Add New Device" />
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >
                    <BoxItem>
                        <Formik
                            initialValues={{ name: '', email: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {
                                ({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                    <Form>
                                        <Field
                                            as={TextField}
                                            // autoFocus
                                            fullWidth
                                            margin="normal"
                                            variant="standard"
                                            id="name"
                                            name="name"
                                            label="Name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                        <Field
                                            as={TextField}

                                            fullWidth
                                            margin="normal"
                                            variant="standard"
                                            id="email"
                                            name="email"
                                            label="Email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                        />
                                        {/* <Button onClick={}>Cancel</Button> */}
                                        <Button variant="contained" type="submit">Add</Button>
                                    </Form>
                                )
                            }
                        </Formik>
                    </BoxItem>
                </Stack>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleClose}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    );
}
export default AddUser;