import { useState, useEffect } from "react";
import _ from "lodash";
import axios from "../../utils/axios";
import { useFormik } from "formik";
import * as Yup from "yup";
// @Mui
import {
    Box, Typography, Stack, Grid, TextField, FormControl, FormLabel, FormHelperText, MenuItem, IconButton, Button, Divider,
    Dialog, Avatar,
    DialogActions,
    DialogContent, DialogTitle, Alert,
} from "@mui/material";
// @Mui-icons
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import CloseIcon from '@mui/icons-material/Close';


// Custom Components
import { PaperItem, BoxItem } from "../../components/Common/Item.component";
import Loading from "../../components/Common/Loading.component";
import CreateIcon from "../../assets/Create.svg";

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    device: Yup.string().required('Device is required'),
    slave: Yup.string().required('Slave device is required'),
    columnKeys: Yup.array().of(
        Yup.object().shape({
            key: Yup.string().required('Column key is required'),
            label: Yup.string().required('Label is required'),
            unit: Yup.string(),
            multiplier: Yup.number()
        })
    )

})
const initialValues = {
    name: "",
    device: "",
    slave: "",

    columnKeys: [{ key: "", label: "", unit: "", multiplier: "" }]
}
export default function CreateDataTable({
    open,
    handleClose,
    handleSnakbarOpen,
    refreshData,
    setMessage,
}) {
    const [deviceList, setDeviceList] = useState([]);
    const [slaveList, setSlaveList] = useState([]);
    const [keyList, setKeyList] = useState([]);
    const [alertMessage, setAlertMessage] = useState(null);
    const [showAlert, setAlert] = useState(false);

    const postData = (data) => {
        axios.post('/monitor/createtable', data)
            .then(res => {
                setMessage(res.data);
                handleSnakbarOpen();
                refreshData();
                handleClose();
            })
            .catch(err => {
                setMessage({
                    status: false,
                    message: err.response.data
                })
                handleSnakbarOpen();
            });
    }
    const getDevices = () => {
        axios.get('/devices/list').then(
            res => {
                if (_.isArray(res.data)) {
                    setDeviceList(res.data);
                } else {
                    setAlertMessage("Something went wrong while fetching devices");
                    setAlert(true);
                }
            }
        ).catch(err => {
            console.error('error occurred while fetching devices', err);
        });
    }
    const getSlaveDevices = (id) => {
        axios.get(`/slaves/device/${id}`).then(res => {
            if (_.isArray(res.data)) {
                setSlaveList(res.data);
            } else {
                setAlertMessage("Something went wrong while fetching slave devices");
                setAlert(true);
            }
        }).catch(err => {
            console.error('error occurred while fetching slave devices', err);
        })
    }
    const getColumnKeys = (deviceId, slaveId) => {
        axios.get(`/slaves/keylist`, { params: { id: deviceId, slaveId } })
            .then(res => {
                if (_.isArray(res.data)) {
                    setKeyList(res.data);
                } else {
                    setAlertMessage(
                        (_.has(res.data, 'message')) ? res.data.message :
                            "Something went wrong while fetching data keys"
                    );
                    setAlert(true);
                }
            })
            .catch(err => {
                console.error('error occurred while fetching keys of slaves', err);
            });
    }
    const handleName = (e) => {
        formik.setFieldValue('name', e.target.value.toUpperCase());
    }
    const handleAddNewColumn = () => {
        const colKeys = [...formik.values.columnKeys];
        colKeys.push({ key: "", label: "", unit: "", multiplier: "" });
        formik.setFieldValue('columnKeys', colKeys);
    }
    const handleRemoveColumn = (index) => {
        const colKeys = [...formik.values.columnKeys];
        colKeys.splice(index, 1);
        formik.setFieldValue('columnKeys', colKeys);
    }
    const handleColumChange = (index, value) => {
        const colKeys = [...formik.values.columnKeys];
        colKeys[index] = { ...colKeys[index], key: value };
        formik.setFieldValue('columnKeys', colKeys);
    }
    const handleLabelChange = (index, value) => {
        const colKeys = [...formik.values.columnKeys];
        colKeys[index] = { ...colKeys[index], label: value };
        formik.setFieldValue('columnKeys', colKeys);
    }
    const handleUnitChange = (index, value) => {
        const colKeys = [...formik.values.columnKeys];
        colKeys[index] = { ...colKeys[index], unit: value };
        formik.setFieldValue('columnKeys', colKeys);
    }
    const handleMultiplierChange = (index, value) => {
        const colKeys = [...formik.values.columnKeys];
        colKeys[index] = { ...colKeys[index], multiplier: value };
        formik.setFieldValue('columnKeys', colKeys);
    }
    const handleDeviceChange = (e) => {
        const selectedDevice = e.target.value;
        formik.setFieldValue('device', selectedDevice);
        formik.setFieldValue('slave', '');
        formik.setFieldValue('columnKeys', [{ key: "", label: "", unit: "" }]);
        if (selectedDevice) {
            getSlaveDevices(selectedDevice);
        } else {
            setSlaveList([]);
        }
        setKeyList([]);

    }
    const handleSlaveDevice = (e) => {
        const selectedSlave = e.target.value;
        formik.setFieldValue('slave', selectedSlave);
        formik.setFieldValue('columnKeys', [{ key: "", label: "", unit: "" }]);
        if (selectedSlave) {
            getColumnKeys(formik.values.device, selectedSlave);
        } else {
            setSlaveList([]);
        }
        setKeyList([]);
    }
    // const 
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: v => {
            // alert(JSON.stringify(v, null, 2));
            // console.log('form output', JSON.stringify(v, null, 2));
            postData(v);
        }
    });
    useEffect(() => {
        if (open) {
            getDevices();
        }
        return () => {
            setDeviceList([]);
            setSlaveList([]);
            setKeyList([]);
            setAlertMessage('');
            setAlert(false);
            formik.resetForm(initialValues);
        }
    }, [open]);

    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                scroll='paper'
                maxWidth="lg"
            >
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>Create Data Table</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[800],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <Stack
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={5}
                        >
                            {/* Name and Selections */}
                            <BoxItem>
                                <img src={CreateIcon} width='120px' height={'auto'} alt='create icon' />
                            </BoxItem>
                            <PaperItem elevation={4} sx={{ width: '100%', p: 2 }}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={3}
                                >
                                    <Grid item md lg={4} xl={4}>
                                        <TextField
                                            fullWidth
                                            // focused
                                            // required
                                            size="small"
                                            color="secondary"
                                            id="table-name"
                                            name="table-name"
                                            label="Table Name"
                                            value={formik.values.name}
                                            onBlur={formik.handleBlur}
                                            onChange={handleName}
                                            error={formik.touched.name && Boolean(formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                        />
                                    </Grid>
                                    <Grid item md lg={4} xl={4}>
                                        <FormControl
                                            fullWidth
                                            error={formik.touched.device && Boolean(formik.errors.device)}
                                        >
                                            {/* <FormLabel > */}
                                            <TextField
                                                select
                                                fullWidth
                                                // focused
                                                // required
                                                size="small"
                                                color="secondary"
                                                id="select-device"
                                                name="selct-device"
                                                label="Select Device"
                                                value={formik.values.device}
                                                onChange={handleDeviceChange}
                                                onBlur={formik.handleBlur}
                                            >
                                                {
                                                    deviceList ? deviceList.map((device) => (
                                                        <MenuItem key={device.deviceId} value={device.deviceId}>{`${device.name} - ${device.label}`}</MenuItem>
                                                    )) : <MenuItem>None</MenuItem>
                                                }

                                            </TextField>
                                            <FormHelperText>{formik.touched.device && formik.errors.device}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item md lg={4} xl={4}>
                                        <FormControl
                                            fullWidth
                                            error={formik.touched.city && Boolean(formik.errors.city)}
                                        >
                                            <TextField
                                                select
                                                fullWidth
                                                // focused
                                                // required
                                                size="small"
                                                color="secondary"
                                                id="select-slave-device"
                                                name="select-slave-device"
                                                label="Select Slave"
                                                value={formik.values.slave}
                                                onChange={handleSlaveDevice}
                                            // disabled={!formik.values.slave}
                                            >
                                                {
                                                    slaveList ? slaveList.map((slave) => (
                                                        <MenuItem key={slave.id} value={slave.sdId}>{`${slave.sdId} -${slave.name.toUpperCase()}`}</MenuItem>
                                                    )) : <MenuItem>None</MenuItem>
                                                }
                                            </TextField>
                                            <FormHelperText>{formik.touched.slave && formik.errors.slave}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </PaperItem>
                            <PaperItem elevation={4} sx={{ width: '100%', p: 2 }}>
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={3}
                                    sx={{ width: '100%' }}
                                >
                                    <Grid item md={12} lg={12} xl={12}>
                                        <BoxItem>
                                            <Typography variant="h6">Column keys</Typography>
                                        </BoxItem>
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12} xl={12} sx={{ width: '100%' }}>
                                        <BoxItem sx={{ width: '100%' }}>
                                            <Stack
                                                direction="column"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                                spacing={1}
                                                sx={{ width: '100%' }}
                                            >
                                                {
                                                    formik.values.columnKeys.map((col, index) => (
                                                        <BoxItem key={index} sx={{ width: '100%' }}>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justifyContent="flex-start"
                                                                alignItems="space-between"
                                                                spacing={3}
                                                                sx={{ width: '100%' }}
                                                            >
                                                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                                    <FormControl
                                                                        fullWidth
                                                                        error={formik.touched.columnKeys && Boolean(formik.errors.columnKeys)}
                                                                    >
                                                                        <TextField
                                                                            select
                                                                            fullWidth
                                                                            size="small"
                                                                            // focused
                                                                            color="secondary"
                                                                            id={`select-key-${index}`}
                                                                            name={`select-key-${index}`}
                                                                            label="Select Key"
                                                                            value={col.key}
                                                                            onChange={(e) => handleColumChange(index, e.target.value)}
                                                                            onBlur={formik.handleBlur}
                                                                        >
                                                                            {/* {
                                                deviceList.map((device, index )=> (
                                                    <MenuItem key={index} value={device}></MenuItem>
                                                ))
                                            } */}
                                                                            {keyList.map(k => <MenuItem key={k} value={k} >{k}</MenuItem>)
                                                                            }
                                                                        </TextField>
                                                                        {/* <FormHelperText>{formik.touched.columnKeys && formik.errors.columnKeys}</FormHelperText> */}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                                    <FormControl
                                                                        fullWidth
                                                                        error={formik.touched.columnKeys && Boolean(formik.errors.columnKeys)}
                                                                    >
                                                                        <TextField
                                                                            fullWidth
                                                                            size="small"
                                                                            color="secondary"
                                                                            id={`column-label-${index}`}
                                                                            name={`column-label-${index}`}
                                                                            label="Column Label"
                                                                            value={col.label}
                                                                            onChange={e => handleLabelChange(index, e.target.value.toUpperCase())}
                                                                        />
                                                                        {/* <FormHelperText>{formik.touched.columnKeys && formik.errors.columnKeys}</FormHelperText> */}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>

                                                                    <FormControl
                                                                        fullWidth
                                                                        error={formik.touched.columnKeys && Boolean(formik.errors.columnKeys)}
                                                                    >
                                                                        <TextField
                                                                            fullWidth
                                                                            size="small"
                                                                            color="secondary"
                                                                            id={`multiplier-${index}`}
                                                                            name={`multiplier-${index}`}
                                                                            label="Multiplier"
                                                                            value={col.multiplier}
                                                                            onChange={e => handleMultiplierChange(index, e.target.value)}
                                                                        />
                                                                        {/* <FormHelperText>{formik.touched.columnKeys && formik.errors.columnKeys}</FormHelperText> */}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                                    <FormControl
                                                                        fullWidth
                                                                        error={formik.touched.columnKeys && Boolean(formik.errors.columnKeys)}
                                                                    >
                                                                        <TextField
                                                                            fullWidth
                                                                            size="small"
                                                                            color="secondary"
                                                                            id={`unit-${index}`}
                                                                            name={`unit-${index}`}
                                                                            label="Unit"
                                                                            value={col.unit}
                                                                            onChange={e => handleUnitChange(index, e.target.value)}
                                                                        />
                                                                        {/* <FormHelperText>{formik.touched.columnKeys && formik.errors.columnKeys}</FormHelperText> */}
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                                    <IconButton
                                                                        // sx={{ mt: 1 }}
                                                                        onClick={() => handleRemoveColumn(index)}
                                                                        variant="outlined"
                                                                        aria-label="Remove"
                                                                        color="error"
                                                                    >
                                                                        <RemoveCircleRoundedIcon />
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </BoxItem>)
                                                    )
                                                }
                                            </Stack>
                                        </BoxItem>
                                    </Grid>
                                    <Grid item md={12} lg={12} xl={12}>
                                        <IconButton
                                            sx={{ ml: 3 }}
                                            onClick={handleAddNewColumn}
                                            variant="outlined"
                                            aria-label="add-new-column-key"
                                            color="secondary"
                                        >
                                            <AddCircleRoundedIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </PaperItem>
                            {/* <PaperItem elevation={4}>
                            <Grid
                                    container
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={3}
                                    sx={{ width: '100%' }}
                                >
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    
                                    </Grid>
                                </Grid>
                            </PaperItem> */}
                            {
                                showAlert ?
                                    <PaperItem elevation={4} sx={{ width: '100%', float: 'left' }}>
                                        <Alert severity="error">{alertMessage}</Alert>
                                    </PaperItem> : null
                            }
                        </Stack>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                        >
                            Create
                        </Button>
                    </DialogActions>
                </form>
            </Dialog >
        </Box >
    );
}