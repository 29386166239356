import _ from 'lodash';
import dayjs from 'dayjs';
import { jwtDecode } from "jwt-decode";
import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import { applyMiddleware } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducer from './reducers';
import { LOGIN_SUCCESS, VERIFY_LOGIN, LOGOUT } from './actions/types';
import { getUserFromToken } from '../helper/Utility.helper';




// const preloadedState = {
//     auth: {
//         isAuthenticated: false,
//         user: null, // You may fetch user details from the server here if needed
//     },
// };

// Create store with preloaded state
const store = configureStore({
    reducer: rootReducer,
    // preloadedState,
},
    composeWithDevTools(applyMiddleware(thunk))
);

// Check for existing token in localStorage
const token = localStorage.getItem('token');
if (token === '' || token === undefined) {
    localStorage.clear();
}

// if (!token || token === undefined || token === 'undefined') {
//     store.dispatch({
//         type: LOGOUT,
//     });

// }
// Dispatch login success action if a token exists
// if (token && token !== 'undefined') {
//     const decodedToken = jwtDecode(token);


//     console.log('decoded json', decodedToken);
//     const isTokenExpired = dayjs().isAfter(decodedToken.exp);
//     console.log("is token expired", isTokenExpired);
//     if (isTokenExpired) {
//         store.dispatch({
//             type: LOGOUT,
//         });
//     }
//     // break;
//     // // TODO
//     // // Verify user using backend api
//     // // check response code for expiried token and logout user.
//     // // by updating the redux state
//     // store.dispatch({
//     //     type: LOGIN_SUCCESS,
//     //     payload: {
//     //         user: getUserFromToken(token), // You may fetch user details from the server here if needed
//     //         token,
//     //     },
//     // });
// }

export default store;
