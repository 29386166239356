import { Field } from "formik";
import {
    Checkbox, Typography, Stack, Card, CardHeader, CardContent, FormControlLabel
} from '@mui/material';
import { BoxItem } from "../../components/Common/Item.component";
const PermissionCard = ({ section, values, setFieldValue, handleSelectAll }) => (
    <Card sx={{
        maxWidth: 345,
        height: '100%'
    }}>
        <CardHeader
            action={
                <Checkbox
                    checked={Object.values(values.permissions[section]).every((permission) => permission)}
                    onChange={() =>
                        setFieldValue('permissions', handleSelectAll(values.permissions, section))
                    }
                />
            }
            title={
                <Typography variant="subtitle1" sx={{ color: '#808080' }}>
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                </Typography>
            }
        />
        <CardContent>
            <Stack
                // direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row', xl: 'row' }}
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                // spacing={{ xs: 1, sm: 2, md: 4, lg:4, xl:8 }}
                spacing={0}
            >
                {
                    Object.keys(values.permissions[section]).map((permission) => (
                        <BoxItem sx={{ p: 0 }}>
                            <Field key={permission} type="checkbox" name={`permissions.${section}.${permission}`}>
                                {({ field }) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} />}
                                        label={permission.charAt(0).toUpperCase() + permission.slice(1)}
                                    />
                                )}
                            </Field>
                        </BoxItem>
                    ))
                }
            </Stack>
        </CardContent>
    </Card>
)
export default PermissionCard;