import { useState, useEffect, useMemo } from "react";
import _ from "lodash";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { fNumber } from '../../utils/format-number';
import Chart from 'react-apexcharts';
import axios from "../../utils/axios";
import Loading from "../../components/Common/Loading.component";
const generateRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
const days = 24;

const setSeries = (data) => {
    const rows = [];
    if (Object.keys(data).length === 0) { return []; }

    const sortedData = sortByDateOrderByDesc(data);
    for (let k in sortedData) {
        const cols = []
        for (let i of sortedData[k]) {
            cols.push({
                x: String(i.hour), // Hour of the day
                y: i.count // Count number
            });
        }
        rows.push({ name: dayjs(k).format('MMM, D'), data: cols })
    }
    return rows;
}

const sortByDateOrderByDesc = (data) => {
    const dateKeys = _.keys(data);
    const sortedDateKeys = _.sortBy(dateKeys, date => dayjs(date)).reverse();
    const sortedData = _.fromPairs(
        _.map(sortedDateKeys, date => [date, data[date]])
    );
    return sortedData;
}
export default function DailyCounts({ title, subheader }) {

    const [loading, setLoading] = useState('nothing');
    const [data, setData] = useState();
    const fetchData = async () => {
        setLoading('requesting');
        const response = await axios.get('/monitor/heatmap');
        const result = await response.data;
        setData(result);
        // console.log(setSeries(result.data))
        setLoading('done');
    }

    useEffect(() => {
        fetchData();
    }, []);
    const options = {
        chart: {
            type: 'heatmap',
            // height: 350, // Adjust height as needed
        },
        dataLabels: {
            enabled: true, // Disable data labels for cleaner visuals
        },
        xaxis: {
            labels: {
                show: true, // Hide x-axis labels as they're not relevant here
                formatter: (v) => {
                    return v;//`Hrs: ${v}` 
                }
            },
        },
        yaxis: {
            // type: 'category',
            // categories: data.map((day) => dayjs(day).format('MMM, D')),
            labels: {
                show: true, // Hide y-axis labels as they're not relevant here

                // formatter: (value) => { return dayjs(value).format('MMM, D') }
            },
        },
        // colors: [
        //     "#48a89d",
        // ],
        tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {

                return (
                    '<div class="arrow_box">' +
                    "<span>Hour: " +
                    w.globals.labels[dataPointIndex] +
                    "</span><br/><span>Payloads: " +

                    series[seriesIndex][dataPointIndex] +
                    "</span>" +
                    "</div>"
                )
            }
        },
        plotOptions: {
            heatmap: {
                shadeIntensity: 0.5,
                useFillColorAsStroke: true,
                radius: 3,
                stroke: {
                    width: 1
                },
            }
        }
    };
    return (
        <Card>
            <CardHeader
                title={title} subheader={subheader} sx={{ mb: 5 }}
            />
            {
                loading === 'done' ?
                    (
                        <Chart
                            dir="ltr"
                            type="heatmap"
                            options={options}
                            series={setSeries(data.data)}
                            height={280}
                        />
                    ) : <Loading />
            }
        </Card>
    );
}