import { Box, } from "@mui/material";

// custom components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import { AppView } from '../../sections/DataVisualization/view';
const Overview = () => {
    return (
        <Box>
            <SectionHeader heading="Overview" />
            <Box>
                {/* <Devices /> */}
                <AppView />
            </Box>
        </Box>
    );
}
export default Overview;