import DataTable from "react-data-table-component";
import Checkbox from "@mui/material/Checkbox";
import ReactLoading from "react-loading";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const sortIcon = <ArrowDropDownIcon />
const selectProps = { inderterminate: isIndeterminate => isIndeterminate };

const DataTableBase = (props) => {
    return (
        <DataTable
            dense
            pagination
            selectableRowsComponent={Checkbox}
            selectableRowsComponentProps={selectProps}
            sortIcon={sortIcon}
            selectableRows
            selectableRowsHighlight
            highlightOnHover
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25]}
            progressComponent={< ReactLoading type='bars' color='#FBBD08' />}
            {...props}
        />
    );
}

export default DataTableBase;