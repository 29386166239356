import * as React from 'react';
import { Typography, Link } from '@mui/material';


export default function Copyright(props) {
    const url = "https://atreyo.in";
    return (
        <Typography
            // variant="body2"
            variant="caption"
            color="text.secondary"
            align="center"
            {...props}
        >
            {`Copyright © ${new Date().getFullYear()} `}
            <Link color="inherit" href={url} target='_blank'>
                {'Atreyo Research And Development LLP.'}
            </Link>{' '}
        </Typography>
    );
};