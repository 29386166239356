import { Routes, Route } from "react-router-dom";

import SlaveList from "./SlaveList.page";
import AddSlave from "../Forms/Slave/AddSlave.page";
import EditSlave from "../Forms/Slave/EditSlave.page";
export default function SlaveDevicesMain() {
    return (
        <Routes>
            <Route path="/" element={<SlaveList />} />
            <Route path="/:id/edit" element={<EditSlave />} />
            <Route path='/add' element={<AddSlave />} />
        </Routes>
    );
}
