import { useState, useEffect, } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import axios from '../../utils/axios';
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
// MUI Components
import {
    Box, Chip, Snackbar, Alert
} from "@mui/material";

import { FaCircle } from "react-icons/fa";
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
// custom components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import DataTable from '../../components/DataTable/Datatable.component';
import { actions, contextActions } from '../../components/DataTable/DTHelper.component'
import AddButton from '../../components/Common/AddButton.comonent';
import NavigateTo from "../../components/Common/NavigateTo.component";

// DataTable column configuration
const columns = [
    {
        name: "Device Id",
        selector: row => row['deviceId'],
        sortable: true,
        left: true
    },
    {
        name: "Name",
        selector: row => row['name'],
        sortable: true,
        left: true

    },
    {
        name: "Label",
        selector: row => row['label'],
        sortable: false,
        left: true
    },
    // {
    //     name: "Active Status",
    //     selector: row => row['isActive'],
    //     sortable: false,
    //     cell: row => (row['isActive'] === "connected") ?
    //         <Chip icon={<FaCircle size="16px" />} label="Online" color="success" size="small" variant="outlined" />
    //         :
    //         <Chip icon={<FaCircle size="16px" />} label="Offline" color="error" size="small" variant="outlined" />
    // },
    {
        name: "Created At",
        selector: row => row['createdAt'],
        sortable: true,
        cell: row => dayjs(row.createdAt).format('lll'),
        // right: true

    },
    {
        id: "actions",
        name: "View Device",
        center: true,
        cell: row => <NavigateTo to={row['deviceId']} componentIcon={<ArticleRoundedIcon />} />
    }
];


function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        const l = d.label.toLowerCase();
        const did = d.deviceId.toLowerCase();
        return n.includes(query) || l.includes(query) || did.includes(query);
    }, data);
    return filteredData
}

const Devices = () => {
    dayjs.extend(localizedFormat);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [queryInput, setQueryInput] = useState("");
    // const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleNavigate = () => navigate('add');
    const handleSnakbarClose = () => {
        setMessage(false);
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }


    const deleteAll = () => {
        // const rows = selectedRows.map(r => r.id);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, selectedRows, 'id'));
        }
    }

    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }

    const getData = async () => {
        setLoading(true);
        const response = await axios.get('/devices');
        const data = await response.data;
        setData(data);
    }

    /**
     * TODO
     * add delete feature for multiple selection
     */

    // const deleteData = async () => {
    //     setLoading(true);
    //     const response = await axios.delete('/devices/many')
    // }

    useEffect(() => { getData(); }, []);
    useEffect(() => {
        if (!_.isEmpty(data)) setLoading(false);
    }, [data]);

    return (
        <Box>
            <SectionHeader heading="Devices" />
            <Box sx={{ mt: 1 }}>
                <DataTable
                    title={<AddButton handleNavigate={handleNavigate} buttonText={'Device'} />}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField="name"
                    defaultSortFieldId={1}
                    actions={actions({ id: 'device-search-box', handleChange: handleQueryInput, getData })}
                    contextActions={contextActions(deleteAll)}
                    onSelectedRowsChange={handleChange}
                    selectableRowsNoSelectAll
                    clearSelectedRows={toggleCleared}
                    // onRowClicked={handleRowClicked}
                    progressPending={loading}
                />
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={message}
                onClose={handleSnakbarClose}
                autoHideDuration={4000}
            >
                <Alert severity="success" onClose={handleSnakbarClose}>
                    {
                        "Added successfully"
                    }
                </Alert>
            </Snackbar>
        </Box>
    );
}
export default Devices;