import { useState, useRef, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import DataTable from '../../components/DataTable/Datatable.component';
import axios from '../../utils/axios';

const columns = [
    {
        id: 1,
        name: 'Register',
        selector: row => row['address'],
    },
    {
        id: 2,
        name: 'Attribute',
        selector: row => row['attribute'],
    },
    {
        id: 3,
        name: 'Unit',
        selector: row => row['unit'],
    }
]

const ScrollDialog = ({ id, openQV, handleQVClose, descriptionElementRef }) => {
    const [data, setData] = useState([]);
    const getData = async () => {
        const response = await axios.get(`slaves/${id}`);
        const result = await response.data;
        setData(result.configuration);
    }
    useEffect(() => {
        if (openQV && id !== null) {
            getData();
        }
    }, [openQV])

    return (
        <>
            <Dialog
                open={openQV}
                onClose={handleQVClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Quick View</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                    >
                        <DataTable
                            // title={
                            //     <Button
                            //         size='small'
                            //         variant='outlined'
                            //         startIcon={<AddRoundedIcon />}
                            //         onClick={handleOpen}
                            //     >Add Slave</Button>
                            // }
                            columns={columns}
                            data={data}
                            defaultSortField="Register"
                            defaultSortFieldId={1}
                            // actions={actions({
                            //     id: 'slavedevice-search-box',
                            //     handleChange: handleQueryInput,
                            //     getData
                            // })}
                            selectableRows={false}
                        />
                    </DialogContentText>

                </DialogContent>
            </Dialog>
        </>
    );
};

export default ScrollDialog;