import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import _ from "lodash";
import axios from '../../../utils/axios';
// import * as Yup from "yup";
import { Formik, Form, Field } from 'formik';
import { styled } from "@mui/material/styles";
import {
    Box, Stack, Divider, Grid, Card, CardHeader, TextField, CardContent, Typography,
    Checkbox, FormControlLabel, Button, Snackbar, Alert
} from "@mui/material";
// Illustration
import CheckboxeIllustration from '../../../assets/illustrations/CheckingBoxes.svg';
// custom components
import SectionHeader from "../../../components/SectionHeader/SectionHeader.component";
import { BoxItem, PaperItem } from "../../../components/Common/Item.component";
import data from './data'
import PermissionCard from "../../../layouts/Roles/PermissionCard.layout";

const ScrollContainer = styled(Box)({
    width: '100%',
    padding: 2,
    // paddingTop: 2,
    // paddingBottom: 2,
    // paddingRight: 2,
    // paddingLeft: 2,
    height: 'calc(100vh - 180px)',
    // overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
        width: 10,
    },
    '&::-webkit-scrollbar-track': {
        background: '#e1e1e1',
        borderRadius: 20,
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#808080',
        borderRadius: 20,
        border: '3px solid #e1e1e1',
    },
    '@media (prefers-reduced-motion: reduce)': {
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
});
const CreateRole = () => {
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({});
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const handleSelectAll = (permissions, section) => {
        const allSelected = Object.values(permissions[section]).every((permission) => permission);
        const updatedPermissions = { ...permissions };
        Object.keys(updatedPermissions[section]).forEach((key) => {
            updatedPermissions[section][key] = !allSelected;
        });
        return updatedPermissions;
    };
    const handleClose = () => {
        setOpen(false);
        navigate('/roles', { replace: true });
    }
    const postData = async (data) => {
        const response = await axios.post('/roles', data);
        const result = await response.data;
        setOpen(true);
        setMessage(result);
        if (result.status) {
            setTimeout(() => {
                navigate('/roles', { replace: true });
            }, 4000);
        }
    }
    const handleSubmit = (values) => {
        postData(values);
    }
    const getData = async () => {
        setInitialValues({
            name: '',
            permissions: data
        })
    }
    useEffect(() => {
        getData();
    }, []);
    return (
        <Box>
            <SectionHeader heading="Create Role" />
            <Box>
                {
                    (!_.isEmpty(initialValues)) ?
                        (
                            <Box>
                                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                                    {({ values, setFieldValue }) => (
                                        <Form>
                                            <Stack
                                                direction="row"
                                                divider={<Divider orientation="vertical" flexItem />}
                                                justifyContent="space-evenly"
                                                alignItems="flex-start"
                                                spacing={2}
                                            >
                                                <BoxItem>
                                                    <Box sx={{ my: 3, width: '100%' }}>
                                                        <Typography variant="h6">Select Permissions</Typography>
                                                    </Box>
                                                    <ScrollContainer>
                                                        <Grid container spacing={1}>
                                                            {
                                                                Object.keys(values.permissions).map(section => (
                                                                    <Grid key={section} item xs={12} sm={12} md={6} lg={4}>
                                                                        <PermissionCard
                                                                            key={section}
                                                                            section={section}
                                                                            values={values}
                                                                            handleSelectAll={handleSelectAll}
                                                                            setFieldValue={setFieldValue}
                                                                        />
                                                                    </Grid>
                                                                ))
                                                            }
                                                        </Grid>
                                                    </ScrollContainer>
                                                </BoxItem>
                                                <BoxItem sx={{ minWidth: '300px' }}>
                                                    <Stack
                                                        sx={{ mt: 10 }}
                                                        direction="column"
                                                        justifyContent="flex-start"
                                                        alignItems="stretch"
                                                        spacing={3}
                                                    >
                                                        <BoxItem>
                                                            <img src={CheckboxeIllustration} width="150px" height="auto" alt="Role_icon" />
                                                        </BoxItem>
                                                        <BoxItem>
                                                            <TextField
                                                                size="small"
                                                                label="Role name"
                                                                name="name"
                                                                value={values.name}
                                                                onChange={(e) => setFieldValue('name', e.target.value)}
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                        </BoxItem>
                                                        <BoxItem>
                                                            <Button
                                                                size="small"
                                                                type="submit"
                                                                variant="contained"
                                                                disabled={false}
                                                                fullWidth>
                                                                Create Role
                                                            </Button>
                                                        </BoxItem>
                                                    </Stack>
                                                </BoxItem>
                                            </Stack>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        )
                        : null
                }
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleClose}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    );
}
export default CreateRole;