// src/utils/axios.js

import axios from 'axios';
import store from '../redux/store';
import { logout } from '../redux/actions/authActions'
// import cookie from 'js-cookie'
// Add a request interceptor
const instance = axios.create({
    // For Development
    // baseURL: 'http://localhost:5002',

    // For Production
    // baseURL: 'http://139.5.190.68:5002',

    // For AWS 
    baseURL: 'https://umkpmpcvt7.ap-south-1.awsapprunner.com',

    // For Docker internal use
    // baseURL: 'http://atra-data-api:5002',
    // baseURL: 'atra-data-api'
});

// instance.defaults.baseURL = 'http://localhost:5002';
instance.interceptors.request.use(
    (config) => {
        // Retrieve the token from localStorage
        const token = localStorage.getItem('token');

        // Attach the token to the Authorization header
        if (token) {
            if (token === undefined || token === 'undefined' || token === '') {
                store.dispatch(logout());
                return;
            }
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        // console.log('response', response);
        return response;
    },
    async (error) => {
        // Handle request error
        console.log('global response error', error);
        const originalRequest = error.config;
        // console.log('original request', originalRequest);
        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        if (error.response.status === 403) {
            if (error.response.data.message === "Token empty") {
                store.dispatch(logout());
                return;
            }
        }
        if (error.response.status === 401 && !originalRequest._retry) {
            // originalRequest._retry = true;

            // try {
            //     const refreshToken = localStorage.getItem('refreshToken');
            //     const response = await axios.post('/auth/refresh', { refreshToken });
            //     const token = response.headers['Authorization'];
            //     const result = response.data;

            //     localStorage.setItem('token', token);

            //     // Retry the original request with the new token
            //     originalRequest.headers.Authorization = `Bearer ${token}`;
            //     return instance(originalRequest);
            // } catch (error) {
            //     // Handle refresh token error or redirect to login
            // }
            store.dispatch(logout());
            return;
        }

        return Promise.reject(error);
    }
);

export default instance;
